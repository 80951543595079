@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Work Sans", sans-serif;
  /* background-color: #f4f5fd; */
  background-image: url(/static/media/texture1.b8252e8d.jpeg);
}


#page_1 {position:relative; 
    /* overflow: hidden; */
    margin: 15px 0px 10px 0px;
    padding: 0px;border: none;width: 819px;}

#page_1 #p1dimg1 {position:absolute;top:0px;right:31px;z-index:-1;width:172px;height:48px;}
#page_1 #p1dimg1 #p1img1 {width:172px;height:48px;}




.dclr {clear:both;float:none;height:1px;margin:0px;padding:0px;overflow:hidden;}

.ft0{font: bold 26px 'Arial';line-height: 30px;}
.ft1{font: 12px 'Arial';line-height: 15px;}
.ft2{font: bold 12px 'Arial';line-height: 15px;}
.ft3{font: 12px 'Arial';line-height: 13px;}
.ft4{font: 1px 'Arial';line-height: 1px;}
.ft5{font: bold 14px 'Arial';line-height: 16px;}
.ft6{font: bold 14px 'Arial';line-height: 16px;position: relative; bottom: -2px;}
.ft7{font: 1px 'Arial';line-height: 5px;}
.ft8{font: 1px 'Arial';line-height: 3px;}
.ft9{font: 1px 'Arial';line-height: 2px;}
.ft10{font: bold 13px 'Arial';line-height: 16px;}
.ft11{font: bold 14px 'Arial';line-height: 16px;position: relative; bottom: -3px;}

.p0{text-align: left;padding-left: 18px;margin-top: 1px;margin-bottom: 0px;}
.p1{text-align: left;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p2{text-align: right;padding-right: 532px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p3{text-align: right;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p4{text-align: left;padding-left: 320px;margin-top: 28px;margin-bottom: 0px;}
.p5{text-align: left;padding-left: 5px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p6{text-align: left;padding-left: 4px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p7{text-align: right;padding-right: 43px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p8{text-align: left;padding-left: 61px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p9{text-align: left;padding-left: 3px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p10{text-align: right;padding-right: 45px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p11{text-align: left;padding-left: 12px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p12{text-align: left;padding-left: 11px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p13{text-align: left;padding-left: 18px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p14{text-align: left;padding-left: 17px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p15{text-align: left;padding-left: 41px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p16{text-align: left;padding-left: 6px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p17{text-align: left;padding-left: 77px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p18{text-align: center;padding-left: 25px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p19{text-align: center;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p20{text-align: left;padding-left: 2px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p21{text-align: left;padding-left: 37px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p22{text-align: left;padding-left: 15px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p23{text-align: center;padding-left: 26px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p24{text-align: right;padding-right: 8px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p25{text-align: right;padding-right: 19px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p26{text-align: left;padding-left: 305px;margin-top: 7px;margin-bottom: 0px;}
.p27{text-align: left;padding-left: 16px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p28{text-align: left;padding-left: 392px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p29{text-align: center;padding-right: 18px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p30{text-align: center;padding-right: 22px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}

.td0{padding: 0px;margin: 0px;width: 277px;vertical-align: bottom;}
.td1{padding: 0px;margin: 0px;width: 460px;vertical-align: bottom;}
.td2{border-left: #000000 1px solid;border-top: #000000 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 61px;vertical-align: bottom;}
.td3{border-right: #000000 1px solid;border-top: #000000 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 278px;vertical-align: bottom;}
.td4{border-top: #000000 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 103px;vertical-align: bottom;}
.td5{border-top: #000000 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 53px;vertical-align: bottom;}
.td6{border-top: #000000 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 24px;vertical-align: bottom;}
.td7{border-top: #000000 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 78px;vertical-align: bottom;}
.td8{border-top: #000000 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 6px;vertical-align: bottom;}
.td9{border-right: #000000 1px solid;border-top: #000000 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 139px;vertical-align: bottom;}
.td10{border-left: #000000 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 61px;vertical-align: bottom;}
.td11{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 129px;vertical-align: bottom;}
.td12{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 86px;vertical-align: bottom;}
.td13{border-right: #000000 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 63px;vertical-align: bottom;}
.td14{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 27px;vertical-align: bottom;}
.td15{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 35px;vertical-align: bottom;}
.td16{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 6px;vertical-align: bottom;}
.td17{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 21px;vertical-align: bottom;}
.td18{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 14px;vertical-align: bottom;}
.td19{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 53px;vertical-align: bottom;}
.td20{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 24px;vertical-align: bottom;}
.td21{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 78px;vertical-align: bottom;}
.td22{border-right: #000000 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 139px;vertical-align: bottom;}
.td23{border-left: #000000 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 30px;vertical-align: bottom;}
.td24{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 31px;vertical-align: bottom;}
.td25{border-right: #000000 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 278px;vertical-align: bottom;}
.td26{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 102px;vertical-align: bottom;}
.td27{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 62px;vertical-align: bottom;}
.td28{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 112px;vertical-align: bottom;}
.td29{border-left: #000000 1px solid;border-right: #000000 1px solid;padding: 0px;margin: 0px;width: 339px;vertical-align: bottom;}
.td30{padding: 0px;margin: 0px;width: 68px;vertical-align: bottom;}
.td31{border-right: #000000 1px solid;padding: 0px;margin: 0px;width: 335px;vertical-align: bottom;}
.td32{border-left: #000000 1px solid;padding: 0px;margin: 0px;width: 30px;vertical-align: bottom;}
.td33{padding: 0px;margin: 0px;width: 31px;vertical-align: bottom;}
.td34{padding: 0px;margin: 0px;width: 129px;vertical-align: bottom;}
.td35{padding: 0px;margin: 0px;width: 86px;vertical-align: bottom;}
.td36{border-right: #000000 1px solid;padding: 0px;margin: 0px;width: 63px;vertical-align: bottom;}
.td37{padding: 0px;margin: 0px;width: 27px;vertical-align: bottom;}
.td38{padding: 0px;margin: 0px;width: 35px;vertical-align: bottom;}
.td39{padding: 0px;margin: 0px;width: 6px;vertical-align: bottom;}
.td40{padding: 0px;margin: 0px;width: 112px;vertical-align: bottom;}
.td41{padding: 0px;margin: 0px;width: 78px;vertical-align: bottom;}
.td42{border-right: #000000 1px solid;padding: 0px;margin: 0px;width: 139px;vertical-align: bottom;}
.td43{padding: 0px;margin: 0px;width: 190px;vertical-align: bottom;}
.td44{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 160px;vertical-align: bottom;}
.td45{border-left: #000000 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 190px;vertical-align: bottom;}
.td46{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 103px;vertical-align: bottom;}
.td47{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 77px;vertical-align: bottom;}
.td48{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 68px;vertical-align: bottom;}
.td49{border-right: #000000 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 145px;vertical-align: bottom;}
.td50{border-right: #000000 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 223px;vertical-align: bottom;}
.td51{border-right: #000000 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 149px;vertical-align: bottom;}
.td52{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 118px;vertical-align: bottom;}
.td53{border-left: #000000 1px solid;padding: 0px;margin: 0px;width: 190px;vertical-align: bottom;}
.td54{border-right: #000000 1px solid;padding: 0px;margin: 0px;width: 149px;vertical-align: bottom;}
.td55{padding: 0px;margin: 0px;width: 103px;vertical-align: bottom;}
.td56{padding: 0px;margin: 0px;width: 155px;vertical-align: bottom;}
.td57{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 84px;vertical-align: bottom;}
.td58{padding: 0px;margin: 0px;width: 53px;vertical-align: bottom;}
.td59{padding: 0px;margin: 0px;width: 24px;vertical-align: bottom;}
.td60{border-right: #000000 1px solid;padding: 0px;margin: 0px;width: 223px;vertical-align: bottom;}
.td61{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 64px;vertical-align: bottom;}
.td62{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 140px;vertical-align: bottom;}
.td63{border-left: #000000 1px solid;border-right: #c0c0c0 1px solid;padding: 0px;margin: 0px;width: 29px;vertical-align: bottom;}
.td64{border-right: #c0c0c0 1px solid;padding: 0px;margin: 0px;width: 85px;vertical-align: bottom;}
.td65{padding: 0px;margin: 0px;width: 64px;vertical-align: bottom;}
.td66{border-right: #c0c0c0 1px solid;padding: 0px;margin: 0px;width: 26px;vertical-align: bottom;}
.td67{border-right: #c0c0c0 1px solid;padding: 0px;margin: 0px;width: 61px;vertical-align: bottom;}
.td68{padding: 0px;margin: 0px;width: 14px;vertical-align: bottom;}
.td69{border-right: #c0c0c0 1px solid;padding: 0px;margin: 0px;width: 52px;vertical-align: bottom;}
.td70{border-right: #c0c0c0 1px solid;padding: 0px;margin: 0px;width: 5px;vertical-align: bottom;}
.td71{border-left: #000000 1px solid;border-right: #c0c0c0 1px solid;border-top: #000000 1px solid;padding: 0px;margin: 0px;width: 29px;vertical-align: bottom;}
.td72{border-top: #000000 1px solid;padding: 0px;margin: 0px;width: 160px;vertical-align: bottom;}
.td73{border-right: #c0c0c0 1px solid;border-top: #000000 1px solid;padding: 0px;margin: 0px;width: 85px;vertical-align: bottom;}
.td74{border-top: #000000 1px solid;padding: 0px;margin: 0px;width: 64px;vertical-align: bottom;}
.td75{border-right: #c0c0c0 1px solid;border-top: #000000 1px solid;padding: 0px;margin: 0px;width: 26px;vertical-align: bottom;}
.td76{border-right: #c0c0c0 1px solid;border-top: #000000 1px solid;padding: 0px;margin: 0px;width: 61px;vertical-align: bottom;}
.td77{border-top: #000000 1px solid;padding: 0px;margin: 0px;width: 14px;vertical-align: bottom;}
.td78{border-right: #c0c0c0 1px solid;border-top: #000000 1px solid;padding: 0px;margin: 0px;width: 52px;vertical-align: bottom;}
.td79{border-top: #000000 1px solid;padding: 0px;margin: 0px;width: 24px;vertical-align: bottom;}
.td80{border-top: #000000 1px solid;padding: 0px;margin: 0px;width: 78px;vertical-align: bottom;}
.td81{border-right: #c0c0c0 1px solid;border-top: #000000 1px solid;padding: 0px;margin: 0px;width: 5px;vertical-align: bottom;}
.td82{border-right: #000000 1px solid;border-top: #000000 1px solid;padding: 0px;margin: 0px;width: 139px;vertical-align: bottom;}
.td83{border-right: #c0c0c0 1px solid;padding: 0px;margin: 0px;width: 20px;vertical-align: bottom;}
.td84{border-left: #000000 1px solid;border-right: #c0c0c0 1px solid;border-top: #c0c0c0 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 29px;vertical-align: bottom;}
.td85{border-top: #c0c0c0 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 31px;vertical-align: bottom;}
.td86{border-top: #c0c0c0 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 129px;vertical-align: bottom;}
.td87{border-right: #c0c0c0 1px solid;border-top: #c0c0c0 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 85px;vertical-align: bottom;}
.td88{border-top: #c0c0c0 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 64px;vertical-align: bottom;}
.td89{border-right: #c0c0c0 1px solid;border-top: #c0c0c0 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 26px;vertical-align: bottom;}
.td90{border-top: #c0c0c0 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 35px;vertical-align: bottom;}
.td91{border-top: #c0c0c0 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 6px;vertical-align: bottom;}
.td92{border-right: #c0c0c0 1px solid;border-top: #c0c0c0 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 20px;vertical-align: bottom;}
.td93{border-top: #c0c0c0 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 14px;vertical-align: bottom;}
.td94{border-right: #c0c0c0 1px solid;border-top: #c0c0c0 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 52px;vertical-align: bottom;}
.td95{border-top: #c0c0c0 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 24px;vertical-align: bottom;}
.td96{border-top: #c0c0c0 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 78px;vertical-align: bottom;}
.td97{border-right: #c0c0c0 1px solid;border-top: #c0c0c0 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 5px;vertical-align: bottom;}
.td98{border-right: #000000 1px solid;border-top: #c0c0c0 1px solid;border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 139px;vertical-align: bottom;}
.td99{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 143px;vertical-align: bottom;}
.td100{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 429px;vertical-align: bottom;}
.td101{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 172px;vertical-align: bottom;}
.td102{border-bottom: #000000 1px solid;padding: 0px;margin: 0px;width: 1px;vertical-align: bottom;}
.td103{padding: 0px;margin: 0px;width: 1px;vertical-align: bottom;}
.td104{padding: 0px;margin: 0px;width: 143px;vertical-align: bottom;}
.td105{padding: 0px;margin: 0px;width: 429px;vertical-align: bottom;}
.td106{padding: 0px;margin: 0px;width: 172px;vertical-align: bottom;}
.td107{padding: 0px;margin: 0px;width: 572px;vertical-align: bottom;}

.tr0{height: 30px;}
.tr1{height: 17px;}
.tr2{height: 13px;}
.tr3{height: 16px;}
.tr4{height: 27px;}
.tr5{height: 21px;}
.tr6{height: 22px;}
.tr7{height: 20px;}
.tr8{height: 5px;}
.tr9{height: 19px;}
.tr10{height: 3px;}
.tr11{height: 2px;}
.tr12{height: 25px;}
.tr13{height: 23px;}
.tr14{height: 319px;}
.tr15{height: 26px;}
.tr16{height: 24px;}
.tr17{height: 59px;}

.t0{width: 772px;margin-left: 2px;margin-top: 2px;font: 12px 'Arial';}
.t1{width: 744px;margin-top: 2px;font: bold 12px 'Arial';}
.t2{width: 745px;margin-top: 1px;font: bold 14px 'Arial';}

.logo-div{
    margin: 40px 30px 30px 50px ;
}
.login-design{
    max-width: '470px';
    max-height: '470px';
    margin: '0% 18%';
    margin-bottom: '15px';
    float: 'center';
}
.login-image-container{
    margin-top: 10%; 
    margin-left: 50px;
    font-size: '18px';
}
.left-pane{
    align-items: center;
    text-align: center;
}
@media only screen and (min-width: 800px){
    .login-image{
        max-width: '550px';
        margin-left: '180px';
    }
}
@media only screen and (max-width: 599px){
    .mobile-hidden {
        display: none;
    }
}
@media only screen and (max-width: 799px){
    .login-image {
        margin-left: '1em';
    }
    .logo-div{
        margin: 30px 30px 30px 35px ;
    }
    .login-image-container{
        /* margin-left: 35px; */
        margin: auto;
    }
    .login-design {
        max-width: 310px;
    }
}
pageContent {
    /* // margin: theme.spacing(3), */
    /* padding: theme.spacing(1), */
    padding: 20px;
    margin: 10px;
    top: 30px;
    padding-top: 30px;
    margin-top: 50px;
}
  /* toolbar theme.mixins.toolbar, */
  paperbg {
    background-color: "#fafafa";
  }
  root {
    /* "& .MuiTextField-root" { */
      background-color: "white";
    /* } */
  }
  viewItem {
    margin-top: "5px";
    padding-top: "12px";
    text-align: "center";
    width: "120px";
    height: "42px";
    background-color: "white";
    border-radius: "5px";
  }
  textCenter {
    text-align: "center";
  }
  remarkInput{
      width: '100%';
  }

  .border-1{
    border: 0px solid black;
  }
