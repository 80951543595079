pageContent {
    /* // margin: theme.spacing(3), */
    /* padding: theme.spacing(1), */
    padding: 20px;
    margin: 10px;
    top: 30px;
    padding-top: 30px;
    margin-top: 50px;
}
  /* toolbar theme.mixins.toolbar, */
  paperbg {
    background-color: "#fafafa";
  }
  root {
    /* "& .MuiTextField-root" { */
      background-color: "white";
    /* } */
  }
  viewItem {
    margin-top: "5px";
    padding-top: "12px";
    text-align: "center";
    width: "120px";
    height: "42px";
    background-color: "white";
    border-radius: "5px";
  }
  textCenter {
    text-align: "center";
  }
  remarkInput{
      width: '100%';
  }

  .border-1{
    border: 0px solid black;
  }