@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Work Sans", sans-serif;
  /* background-color: #f4f5fd; */
  background-image: url("./assets/texture1.jpeg");
}
