.logo-div{
    margin: 40px 30px 30px 50px ;
}
.login-design{
    max-width: '470px';
    max-height: '470px';
    margin: '0% 18%';
    margin-bottom: '15px';
    float: 'center';
}
.login-image-container{
    margin-top: 10%; 
    margin-left: 50px;
    font-size: '18px';
}
.left-pane{
    align-items: center;
    text-align: center;
}
@media only screen and (min-width: 800px){
    .login-image{
        max-width: '550px';
        margin-left: '180px';
    }
}
@media only screen and (max-width: 599px){
    .mobile-hidden {
        display: none;
    }
}
@media only screen and (max-width: 799px){
    .login-image {
        margin-left: '1em';
    }
    .logo-div{
        margin: 30px 30px 30px 35px ;
    }
    .login-image-container{
        /* margin-left: 35px; */
        margin: auto;
    }
    .login-design {
        max-width: 310px;
    }
}